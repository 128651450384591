import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { graphql } from 'gatsby';
import { PageLayout, Title, Icon, RepoCard, Line } from '@josefaidt/gatsby-theme';
import SEO from '@josefaidt/gatsby-theme/src/components/seo';
export const query = graphql`
  query {
    file(name: { eq: "josefaidt_Resume" }) {
      publicURL
      name
    }
    githubUser {
      name
      url
      repositories {
        edges {
          node {
            id
            name
            description
            url
            homepageUrl
            stargazers {
              totalCount
            }
            watchers {
              totalCount
            }
            forks {
              totalCount
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Work"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};

const MDXLayout = ({
  children,
  data,
  ...props
}) => <PageLayout>
    <SEO title="Work" />
    <Title>
      <h1>Work</h1>
      <a href="https://resume.josefaidt.dev" style={{
      marginTop: '1.2rem'
    }}>
        Résumé &rarr;
      </a>
    </Title>
    <Line />
    <h2>Personal Projects</h2>
    <p>
      A truncated list of my most recently-active repositories. For a complete list of visit my{' '}
      <a href="https://github.com/josefaidt?tab=repositories" target="_blank" rel="noopener noreferrer">
        Github profile
      </a>
      .
    </p>
    <div className="repo-list--container">
      {data.githubUser.repositories.edges.map(({
      node: r
    }, i) => <a className="link card" href={r.url} target="_blank" rel="noopener noreferrer" key={i} style={{
      textDecoration: 'none'
    }}>
          <RepoCard {...r} />
        </a>)}
    </div>
    <br />
    <div>{children}</div>
  </PageLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "education"
    }}>{`Education`}</h2>
    <hr></hr>
    <h3 {...{
      "id": "louisiana-state-university-baton-rouge-la"
    }}>{`Louisiana State University, Baton Rouge, LA`}</h3>
    <p>{`Bachelor of Science – Information Systems and Decision Sciences
Activities: LSU Tiger Marching Band, Association of IT Professionals LSU Chapter`}</p>
    <br />
    <h2 {...{
      "id": "accomplishments"
    }}>{`Accomplishments`}</h2>
    <hr></hr>
    <h3 {...{
      "id": "boy-scouts-of-america--eagle-scout"
    }}>{`Boy Scouts of America – Eagle Scout`}</h3>
    <p>{`2009 Philmont Scout Ranch attendee`}<br />{`2010 Centennial National Scout Jamboree attendee`}</p>
    <br />
    <h2 {...{
      "id": "volunteer-work"
    }}>{`Volunteer Work`}</h2>
    <hr></hr>
    <h3 {...{
      "id": "geaux-big-baton-rouge-2015"
    }}>{`Geaux BIG Baton Rouge 2015`}</h3>
    <p>{`Annual event held by LSU Campus Life to give a streamlined opportunity for students to give back to the community; involves simple tasks such as landscaping, pressure washing, painting, and elderly assistance`}</p>
    <h3 {...{
      "id": "our-lady-of-the-lake-rmc"
    }}>{`Our Lady of the Lake RMC`}</h3>
    <p>{`Provided DJ and music entertainment services for community events such as annual Easter and Halloween celebrations. Also participated as a member of the company-sponsored team for the Jambalaya Jam 2018 event, which benefits the Capital Area United Way campaign.`}</p>
    <h3 {...{
      "id": "krewe-of-apollo-baton-rouge"
    }}>{`Krewe of Apollo Baton Rouge`}</h3>
    <p>{`Provided DJ and music entertainment services for various events such as local concerts, crowning galas, and fundraising ball`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      